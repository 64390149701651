let domain = window.location.hostname

export let apiHost = ''
if (domain.indexOf('localhost') > -1) { // CMS is the local version
	apiHost = 'http://localhost:4000'
} else { // CMS is the remote version (cms.kersentech.com)
	apiHost = 'https://api.kersentech.com'
}

// export {apiHost}
