import React, {useEffect, useState} from "react"
import {connect} from 'react-redux'
import {apiHost} from "../global"
import API from "../library/API";

function PageContent(props) {

	const ln = props.ln

	let videoStyle = {
		margin: '0 0 30px',
		width: '100%'
	}

	return (
		<>
			{
				props.video && props.video !== 'null' &&
				<video controls autoPlay={true} style={videoStyle}>
					<source src={apiHost + '/media/pages/' + props.video} />
				</video>
			}
			<div className={props.className} dangerouslySetInnerHTML={{ __html: props.body && props.body[ln] }} />
		</>
	)

}

const mapState = state => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState)(PageContent)
