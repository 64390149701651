import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import playIcon from "../images/icons/play.png"
import wavesBg from "../images/home/waves.png"
import consumerElectronicsIcon from 'images/icons/categories/consumer-electronics.png'
import automobileIcon from 'images/icons/categories/automobile.png'
import renewableEnergyIcon from 'images/icons/categories/renewable-energy.png'
import medicalEquipmentIcon from 'images/icons/categories/medical-equipment.png'


// import solarPanelBg from '../images/home/solar-panel-overhead.jpg'

import s from './Home.module.sass'
import API from "../library/API";
import NewsFeed from "../components/NewsFeed";
import {apiHost} from "../global";

const iconIndex = {
	'Consumer Electronics': consumerElectronicsIcon,
	'Automobile': automobileIcon,
	'Renewable Energy': renewableEnergyIcon,
	'Medical Equipment': medicalEquipmentIcon
}


function HomePage(props) {

	let ln = props.ln
	const [intro, setIntro] = useState({})
	const [windowWidth, setWindowWidth] = useState(0)
	const [useGif, setUseGif] = useState(false)
	const [videoPlaying, setVideoPlaying] = useState(false)

	function togglePlay() {
		// console.log('toggling play')
		// let introVideo = document.getElementById('intro-video')
		// // console.log('video', introVideo)
		// // video.remove()
		// introVideo.play()
		// if (!videoPlaying) {
		// 	introVideo.play()
		// 	introVideo.setAttribute('controls', '')
		// } else {
		// 	introVideo.pause()
		// 	introVideo.removeAttribute('controls')
		// }
		// setVideoPlaying(!videoPlaying)
	}

	function handleResize() {
		setWindowWidth(window.innerWidth)
		if (window.innerWidth > 760) {
			// window.setTimeout(() => {
			// 	let video = document.getElementById('home-banner')
			// 	video.play()
			// }, 100)
		}
	}

	useEffect(() => {

		let match = navigator.userAgent.match(/lenovo|huawei/i)
		if (match) setUseGif(true)

		let api = new API()
		api.getPages({ slug: 'home-intro' })
			.then(res => {
				let page = res.pages[0]
				console.log(page)
				if (page) setIntro(page)
			})

		window.addEventListener('resize', () => {
			handleResize()
		})
		handleResize()

		window.addEventListener('scroll', () => {
			let opacity
			let scrollTop = document.documentElement.scrollTop
			if (scrollTop < 500) {
				opacity = (500 - scrollTop) / 500
			} else {
				opacity = 0
			}
			let content = document.querySelector('.' + s.content)
			let cats = document.querySelector('.' + s.categories)
			if (content) content.style.opacity = opacity
			if (cats) cats.style.opacity = opacity
		})
	}, [])

	let categoryButtons = props.productCategories.map(category => {
		return (
			<Link to={"/products/" + category.slug}>
				<div className={s.icon}>
					<img src={iconIndex[category.name.en]} />
				</div>
				{category.name[ln]}
			</Link>
		)
	})

	let banner
	if (windowWidth < 1100 && useGif === true) {
		banner = <img src={"/media/composite.gif"} alt={"home page banner"} />
	} else {
		banner = (
			<div className={s.introVideo + ' ' + (videoPlaying ? s.active : '')} onClick={togglePlay}>
				<video id={"home-banner"} autoPlay={true} loop muted playsInline={true}>
					<source src={`/media/composite${windowWidth < 760 ? '-m' : ''}.mp4`}/>
				</video>
			</div>
		)
	}

	return (
		<main>
			<div className={s.banner}>

				<div className={s.overlay} />
				<div className={s.fade} />

				{banner}

				<div className={s.content}>

					<div className={s.text}>
						<h1>
							{ln === 'zh' ? <>产品<br />介绍</> : <>Product<br />Intro</>}
						</h1>
						{ln === 'zh' ?
							<>
								关键部件的领先生产商。<br />
								查看一些现有产品
							</> :
							<>A leading producer of critical components.<br />View some existing products</>
						}
					</div>

					<div className={s.categories}>
						<div className={s.tiles}>
							{categoryButtons}
						</div>
					</div>

				</div>


			</div>

			<section className={s.info}>

				{/*<div className={s.bg}>*/}
				{/*	<img src={wavesBg} />*/}
				{/*	<div className={s.fade} />*/}
				{/*</div>*/}

				<div className={s.panel}>
					<div className={s.tab}>
						{ln === 'zh' ? <>公司介绍</> : <>Company Introduction</>}
						<div className={s.icon} />
					</div>

					<div className={s.intro}>

						<div className={s.introVideo + ' ' + (videoPlaying ? s.active : '')} onClick={togglePlay}>
							{/*<img src={playIcon} />*/}
							{
								intro.video &&
								<video id={"intro-video"} controls playsInline={true} preload={true}>
									<source src={apiHost + '/media/pages/' + intro.video} />
								</video>
							}
						</div>

						<div className={s.text}>
							<h2>{intro.title && intro.title[ln]}</h2>
							<div dangerouslySetInnerHTML={{ __html: intro.body && intro.body[ln] }} />
						</div>

					</div>

					{/*<div className={s.features}>*/}
					{/*	<>Add Highlights?</>*/}
					{/*</div>*/}

				</div>

			</section>

			<NewsFeed />

		</main>
	)

}

const mapState = state => {
	return {
		ln: state.session.ln,
		productCategories: state.content.productCategories
	}
}

export default connect(mapState)(HomePage)
