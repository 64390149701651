import React, {useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'

import API from "../library/API"
import JobTile from "../components/JobTile";
import bannerSrc from "../images/layout/business-partners-shaking-hands-couch-meeting.jpg";

function Page(props) {

	const ln = props.ln
	const [jobsData, setJobsData] = useState([])

	useEffect(() => {
		let api = new API()
		api.getJobs()
			.then(res => {
				console.log(res)
				setJobsData(res.jobs)
			})
	}, [])

	let jobs = jobsData.map(job => {
		console.log(job)
		return <JobTile {...job} />
	})

	return (
		<main>
			<div className={"banner"}>
				<img src={bannerSrc} style={{ opacity: 0.5 }} />
				<div className={"fade"} />
				<h1>{ln === 'zh' ? <>人才招聘</> : <>Recruiting</>}</h1>
			</div>

			<section className={"fixed padded"}>
				<div>{jobs}</div>
			</section>

		</main>
	)

}

const mapState = (state) => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState)(Page)
