import React, {useState, useEffect} from 'react'
import {Link} from "react-router-dom"
import cx from 'classnames'
import {connect} from 'react-redux'

import s from './SearchPane.module.sass'
import API from "../library/API"

function SearchPane(props) {

	const ln = props.ln
	const [pageData, setPageData] = useState([])

	useEffect(() => {
		console.log('searching', props.query)
		if (props.query.length < 2) {
			setPageData([])
			return
		}

		let api = new API()
		api.search(props.query)
			.then(res => {
				console.log(res)
				setPageData(res.pages)
			})

	}, [props.query])

	let pages = pageData.map(page => {
		return (
			<Link to={page.link} className={s.page}>
				{page.title[ln]}
			</Link>
		)
	})

	return (
		<div className={cx(s.pane, { [s.active]: props.visible })}>
			<input type={"text"} value={props.query} onChange={props.handleChange} placeholder={"搜索"} />
			<div className={s.results}>
				{pages}
			</div>
		</div>
	)

}

const mapState = (state) => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState)(SearchPane)
