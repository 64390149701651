import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import API from "library/API"
import PostTile from "components/PostTile"
import NewsOptions from "components/NewsOptions"
import Pager from "components/Pager"

import companyBanner from 'images/layout/truck-shipping-aerial.jpg'
import industryBanner from 'images/layout/industrial-stairs-on-track.jpg'

import s from 'pages/News.module.sass'

const titles = {
	company: { zh: '公司新闻', en: 'Company News' },
	industry: { zh: '行业动态', en: 'Industry Trends' }
}

function NewsPage(props) {

	const ln = props.ln
	const [data, setData] = useState([])
	const type = useParams().type
	const [mode, setMode] = useState('tile')

	useEffect(async () => {
		let api = new API()
		let data = await api.getPosts({ type: type, ln: ln })
		setData(data.posts)
	}, [props.ln])


	let articleCount = data.length
	let pageLimit = 6
	let pages = Math.ceil(articleCount / pageLimit)
	const [activePage, setActivePage] = useState(1)
	let startItem = (activePage - 1) * pageLimit
	let endItem = startItem + pageLimit

	function handlePagerClick(page) {
		let titleElement = document.querySelector('h1')
		let top = titleElement.offsetTop
		window.scrollTo({ top: top, behavior: 'smooth' })
		setTimeout(() => setActivePage(page), 500)
	}

	let news = data.map((post, x) => {
		if (x >= startItem && x < endItem) return <PostTile {...post} mode={mode} />
	})

	return (
		<main className={s.wrapper}>
			<div className={"banner3"}>
				<img src={type === 'company' ? companyBanner : industryBanner} style={{ opacity: 1 }} />
				<h1>{titles[type][ln]}</h1>
				<NewsOptions mode={mode} setMode={setMode} />
			</div>

			<section className={"fixed3"}>
				<div className={s.posts} style={{ marginTop: '50px' }}>{news}</div>

				<Pager pages={pages} activePage={activePage} handlePagerClick={handlePagerClick} />

			</section>

		</main>
	)

}

const mapState = (state) => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState)(NewsPage)
