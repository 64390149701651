import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'

import s from './HR.module.sass'

import banner from  'images/layout/paper-signing.jpeg'

import API from "../library/API"
import PageContent from "../components/PageContent"

function Page(props) {

	const ln = props.ln
	const [page, setPage] = useState({})

	useEffect(() => {
		let api = new API()
		api.getPages({ slug: 'hr' })
			.then(res => {
				let page = res.pages[0]
				if (page) setPage(page)
			})

		setTimeout(() => {
			removeInlineStyles()
		}, 100)

	}, [props.ln])

	function removeInlineStyles() {
		let figures = document.querySelectorAll('figure')
		for (let figure of figures) {
			console.log(figure)
			figure.style = ''
		}
	}

	return (
		<main>
			<div className={"banner"}>
				<img src={banner} />
				<div className={"fade"} />
				<h1>{ln === 'zh' ? <>人力资源政策</> : <>Human Resources Policy</>}</h1>
			</div>

			<section className={"fixed padded " + s.wrapper}>
				<PageContent {...page} />
			</section>

		</main>
	)

}

const mapState = (state) => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState)(Page)
