
const Links = [
	{
		name: { zh: '走进科森', en: 'Kersentech' },
		links: [
			{
				name: { zh: '首页', en: 'Home' },
				href: '/'
			},
			{
				name: { zh: '企业简介', en: 'Introduction' },
				href: '/introduction'
			},
			{
				name: { zh: '董事长致辞', en: 'Message from the Chairman' },
				href: '/chairman' //about?xxx
			},
			{
				name: { zh: '荣誉&实力', en: 'Awards' },
				href: '/awards'
			},
			{
				name: { zh: '企业文化', en: 'Company Culture' },
				href: '/culture'
			},
			{
				name: { zh: '社会责任', en: 'Corporate Responsibility' },
				href: '/responsibility'
			}
		]
	},
	{
		name: { zh: '产品中心', en: 'Products' },
		links: [
			// {
			// 	name: { zh: '3C电子消费品', en: 'Consumer Electronics' },
			// 	href: '/products/consumer-electronics'
			// },
			// {
			// 	name: { zh: '医疗器械零部件', en: 'Medical Equipment' },
			// 	href: '/products/medical-equipment'
			// },
			// {
			// 	name: { zh: '新能源零部件', en: 'Renewable Energy' },
			// 	href: '/products/renewable-energy' //about?xxx
			// },
			// {
			// 	name: { zh: '汽车零部件', en: 'Automobile' },
			// 	href: '/products/automobile'
			// }
		]
	},
	{
		name: { zh: '研发&品质', en: 'Research' },
		links: [
			{
				name: { zh: '研发创新', en: 'Research & Development' },
				href: '/research-and-development'
			},
			{
				name: { zh: '品质管理', en: 'Quality Management' },
				href: '/quality-management'
			}
		]
	},
	{
		name: { zh: '设备展示', en: 'Equipment' },
		links: [
			// {
			// 	name: { zh: '医疗器械生产设备', en: 'Medical Equipment Production' },
			// 	href: '/equipment/medical-device-production'
			// },
			// {
			// 	name: { zh: '切削设备', en: 'Cutting Equipment' },
			// 	href: '/equipment/cutting'
			// },
			// {
			// 	name: { zh: '模具冲压设备', en: 'Die Stamping Equipment' },
			// 	href: '/equipment/molding-stamping'
			// },
			// {
			// 	name: { zh: '检测设备', en: 'Testing Equipment' },
			// 	href: '/equipment/testing'
			// }
		]
	},
	{
		name: { zh: '新闻中心', en: 'News' },
		links: [
			{
				name: { zh: '公司新闻', en: 'Company News' },
				href: '/news/company'
			},
			{
				name: { zh: '行业动态', en: 'Industry News' },
				href: '/news/industry'
			}
		]
	},
	{
		name: { zh: '更多信息', en: 'More' },
		links: [
			{
				name: { zh: '诚信合作', en: 'Partners' },
				href: '/partners'
			},
			{
				name: { zh: '人力资源政策', en: 'Human Resources Policy' },
				href: '/hr'
			},
			{
				name: { zh: '员工风采', en: 'Staff Presence' },
				href: '/staff'
			},
			{
				name: { zh: '人才招聘', en: 'Recruiting' },
				href: '/recruiting'
			},
			{
				name: { zh: '供应链管理', en: 'Supply Chain Management' },
				href: '/supply-chain'
			},
			{
				name: { zh: '联系我们', en: 'Contact Us' },
				href: '/contact'
			}
		]
	}
]

export default Links
