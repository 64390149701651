import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import bannerSrc from "../images/layout/engineers-using-a-computer.jpg"
import API from "../library/API"
import PageContent from "../components/PageContent"
import s from "./SupplyChain.module.sass"

function SupplyChainPage(props) {

	const ln = props.ln
	const [page, setPage] = useState({})

	useEffect(() => {
		let api = new API()
		api.getPages({ slug: 'supply-chain' })
			.then(res => {
				let page = res.pages[0]
				console.log(page)
				if (page) setPage(page)
			})

	}, [])

	return (
		<main>

			<div className={"banner"}>
				<img src={bannerSrc} style={{ opacity: 0.5 }} />
				<div className={"fade"} />
				<h1>{page.title && page.title[ln]}</h1>
			</div>

			<section className={"fixed padded"}>
				<div className={"body"}>
					<PageContent {...page} className={s.wrapper}/>
				</div>
			</section>

		</main>
	)

}

const mapState = state => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState)(SupplyChainPage)
