import {apiHost} from "global"
import {getRefactoredImage} from "./Core"
import placeholder from 'images/placeholder-news.jpg'

function Post(props = {}) {

	this._id = props._id
	this.cover = props.cover
	this.title = {}
	if (props.title) {
		this.title.en = props.title.en
		this.title.zh = props.title.zh
	}
	this.summary = {}
	if (props.summary) {
		this.summary.en = props.summary.en
		this.summary.zh = props.summary.zh
	}
	this.body = {}
	if (props.body) {
		this.body.en = props.body.en
		this.body.zh = props.body.zh
	}
	this.video = props.video
	console.log(props)
	this.published = props.published

	this.getTitle = (ln) => {
		if (!ln) ln = 'zh'
		return this.title[ln]
	}

	this.getCover = (type) => {
		if (!this.cover || this.cover === 'null') return placeholder
		console.log('cover should be', this.cover)
		if (this.cover.indexOf('http') > -1) return this.cover
		return apiHost + '/media/posts/' + getRefactoredImage(this.cover, type)
	}

}

export default Post
