import {apiHost} from "../global"

class API {

	// Standard functions

	async fetch(path, data) {
		let string = ''
		console.log(data)
		if (data) {
			let x = 0
			for (let key in data) {
				let value = data[key]
				string += (x > 0 ? '&' : '') + key + '=' + value
				x++
			}
		}
		console.log(string)
		let res = await fetch(apiHost + path + '?' + string)
			.then(res => res.json())
			.then(res => {
				return res
			})
		console.log(res)
		return res
	}

	async request(path, data, callback) {
		return new Promise((resolve, reject) => {
			let url = apiHost + path

			let xhr = new XMLHttpRequest()
			xhr.withCredentials = true
			xhr.open('POST', url)
			// xhr.setRequestHeader('Content-type', 'multipart/form-data')

			let formData = this.compileFormData(data)

			if (callback) {
				xhr.onprogress = (e) => {
					console.log(e.loaded, e.total)
					callback(e.loaded, e.total)
				}
			}

			// request state change event
			xhr.onreadystatechange = function () {

				// request completed?
				if (xhr.readyState !== 4) return;

				if (xhr.status === 200) {
					// request successful - show response
					// console.log(xhr.responseText)
					resolve(JSON.parse(xhr.responseText))
				} else {
					// request error
					console.log('HTTP error', xhr.status, xhr.statusText);
				}
			};

			// start request
			console.log(formData)
			xhr.send(formData)
		})
	}

	compileFormData(data) {
		console.log('compiling the files')
		let formData = new FormData()
		if (!data) return formData

		for (let [key, value] of Object.entries(data)) {
			console.log('compiling the files')
			if (value instanceof FileList) { console.log('multiple file upload')
				for (let x = 0; x < value.length; x++) {
					formData.append(key, value[x])
				}
			} else { // Appends everything else as normal, except of non-file objects (inc. arrays)
				console.log('not multiple files')
				if (typeof value === 'object' && !(value instanceof File)) value = JSON.stringify(value)
				formData.append(key, value)
			}
		}

		return formData
	}

	async search(query) {
		return await this.request('/search', { query: query })
	}



	async getPages(data) {
		return await this.request('/page/list', data)
	}




	async getPosts(data) {
		data.status = 1
		return await this.request('/post/list', data)
	}

	async getPost(_id) {
		return await this.request('/post/info', { _id: _id })
	}



	async getProducts(data) {
		return await this.request('/product/list', data)
	}

	async getProduct(_id) {
		return await this.request('/product/info', { _id: _id })
	}



	async getProductCategories(data) {
		return await this.request('/product-category/list', data)
	}

	async getProductCategory(_id) {
		return await this.request('/product-category/info', { _id: _id })
	}




	async getEquipment(data) {
		return await this.request('/equipment/list', data)
	}

	async getEquipmentInfo(_id) {
		return await this.request('/equipment/info', { _id: _id })
	}


	async getEquipmentCategories(data) {
		return await this.request('/equipment-category/list', data)
	}

	async getEquipmentCategory(_id) {
		return await this.request('/equipment-category/info', { _id: _id })
	}




	async getJobs() {
		return await this.request('/job/list')
	}

	async getJob(_id) {
		return await this.request('/job/info', { _id: _id })
	}

	async submitApplication(data) {
		return await this.request('/application/save', data)
	}


	async getJobCategories() {
		return await this.request('/job-category/list')
	}

}

export default API
