import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {useHistory, useParams} from 'react-router-dom'

import API from "library/API"
import Post from "library/Post"
import {getFormattedDate} from "library/Core"
import bannerSrc from "images/layout/pexels-pixabay-416324.jpg"

import s from "./Article.module.sass"
import {apiHost} from "../global"

function ArticlePage(props) {

	const ln = props.ln
	const history = useHistory()
	const params = useParams()
	const [post, setPost] = useState(new Post())

	useEffect(() => {
		let _id = params._id
		let api = new API()
		api.getPost(_id)
			.then(res => {
				console.log(res.post)
				setPost(new Post(res.post))
			})
	}, [])



	function scrollToTop() {
		window.scrollTo({top: 0, behavior: "smooth"})
	}

	return (
		<main className={s.wrapper}>

			<div className={"banner"}>
				<img src={bannerSrc} style={{ opacity: 0.5 }} />
				<div className={"fade"} />
				<h1>{post.title[ln]}</h1>
			</div>

			<section className={"padded fixed"}>

				<div className={s.byline}>
					<div onClick={() => history.goBack()}>{"<"} {ln === 'zh' ? '返回' : 'Back'}</div>
					<div>{getFormattedDate(post.published)}</div>
					<div />
				</div>

				{
					post.video && post.video !== 'null' &&
					<video controls autoPlay={true} style={{ margin: '0 0 30px', width: '100%' }}>
						<source src={apiHost + '/media/posts/' + post.video} />
					</video>
				}

				<div dangerouslySetInnerHTML={{ __html: post.body && post.body[ln] }} />

				<div className={s.footer} onClick={scrollToTop}>
					<div>{ln === 'zh' ? '回到顶部' : 'Back to top'}</div>
				</div>

			</section>

		</main>
	)
}

const mapState = state => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState)(ArticlePage)
