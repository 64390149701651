import s from "./NotFound.module.sass"

export default function Page() {
	return (
		<main>
			<div className={"banner"}>
				<h1>404</h1>
			</div>
			<section className={"padded fixed"}>
				找不到网页
			</section>
		</main>
	)
}
