import React, {useEffect, useState} from 'react'
import {useSelector} from "react-redux"
import {Link, useParams} from "react-router-dom"
import API from "../library/API"
import Equipment from "../library/Equipment"
import bannerSrc from "../images/layout/car-during-assembly.jpg"
import s from "./Product.module.sass"

function EquipmentInfo() {

	const ln = useSelector(state => state.session.ln)
	const categories = useSelector(state => state.content.equipmentCategories)
	const params = useParams()
	const [equipment, setEquipment] = useState(new Equipment())

	useEffect(() => {
		let _id = params._id

		let api = new API()
		api.getEquipmentInfo(_id)
			.then(res => {
				console.log(res)
				setEquipment(new Equipment(res.equipment))
			})
	}, [])

	let cat = categories.find(obj => obj._id === equipment.category)
	let slug = cat && cat.slug

	return (
		<main style={{ paddingBottom: '100px', background: 'black'}}>

			<div className={"bg2"} />

			<div className={"banner2"}>
				<div className={"banner-image"}>
					<img src={bannerSrc} />
					<div className={"banner-image-fade"} />
				</div>
				<h1>{equipment.name[ln]}</h1>
			</div>

			<section className={"fixed2"}>

				<Link className={s.back} to={"/equipment/" + slug}>《 {ln === 'zh' ? '返回' : 'Back'}</Link>

				<div className={s.image}>
					<img src={equipment.getImage()} />
				</div>

				{
					equipment.description[ln] &&
					(
						<div className={s.description}>
							{equipment.description[ln]}
						</div>
					)
				}

			</section>

		</main>
	)

}

export default EquipmentInfo
