import React, {useState, useEffect} from "react"
import cx from 'classnames'
import {useLocation} from 'react-router-dom'
import {connect} from 'react-redux'
import {setLanguage} from "../reducers/Session"
import {NavLink, Link} from "react-router-dom"

import Links from "../library/Links"
import SearchPane from "components/SearchPane"

import searchIcon from '../images/icons/search.png'
import globeIcon from '../images/icons/globe.png'

import s from './Header.module.sass'


function Header(props) {

	let ln = props.ln
	const location = useLocation()
	const [sticky, setSticky] = useState(false)
	const [expanded, setExpanded] = useState(false)
	const [searching, setSearching] = useState(false)

	const [query, setQuery] = useState('')

	useEffect(() => {
		window.addEventListener('scroll', () => {
			let scrollTop = document.documentElement.scrollTop
			if (scrollTop > 200) {
				setSticky(true)
			} else {
				setSticky(false)
			}
		})

	}, [])

	useEffect(() => {
		console.log('history', location)
		setExpanded(false)
	}, [location.pathname])



	let menu = Links.map(cat => {

		let sublinks
		if (cat.name.en === 'Products') {
			sublinks = props.productCategories.map(cat => <NavLink to={"/products/" + cat.slug}>{cat.name[ln]}</NavLink>)
		} else if (cat.name.en === 'Equipment') {
			sublinks = props.equipmentCategories.map(cat => <NavLink to={"/equipment/" + cat.slug}>{cat.name[ln]}</NavLink>)
		} else {
			sublinks = cat.links.map(link => <NavLink to={link.href}>{link.name[ln]}</NavLink>)
		}

		return (
			<div className={s.navTop}>
				<div className={s.line} />
				<NavLink to={"/"}>
					<span ln={ln}>{cat.name[ln]}</span>
				</NavLink>
				<div className={s.dropDown}>
					{sublinks}
				</div>
			</div>
		)
	})

	function toggleSearch() {
		setSearching(!searching)
	}

	function toggleExpand() {
		setExpanded(!expanded)
	}

	function handleChange(e) {
		e.preventDefault()
		setQuery(e.target.value)
	}

	return (
		<div className={cx(s.wrapper, { [s.sticky]: sticky, [s.expanded]: expanded })}>

			<header>

				<Link to={"/"} className={s.logo}>
					{/*<div className={s.bg} />*/}
					<img src={'/logo.png'} />
				</Link>

				{/*<div className={s.functionBar}>*/}

				{/*	<div className={s.bg} />*/}

				{/*	<div className={s.searchBar}>*/}
				{/*		<input type={"text"} placeholder={"search website"} />*/}
				{/*		<img src={searchIcon} />*/}
				{/*	</div>*/}

				{/*	<div className={s.languageSwitcher} onClick={() => props.setLanguage(ln === 'en' ? 'zh' : 'en')}>*/}
				{/*		{ln === 'zh' ? 'English' : '中文'}*/}
				{/*		<img src={globeIcon} />*/}
				{/*	</div>*/}

				{/*</div>*/}

				<div className={s.menuBtn} onClick={toggleExpand}>
					<span />
					<span />
					<span />
				</div>

				<div className={s.panelBg} onClick={toggleExpand} />

				<div className={s.panel}>

					<div className={s.languageSwitcher} onClick={() => props.setLanguage(ln === 'en' ? 'zh' : 'en')}>
						<div className={s.switch}>
							<span className={ln === 'zh' ? s.active : ''}>中文</span>
							<span className={ln === 'en' ? s.active : ''}>EN</span>
							<div className={s.slider + ' ' + (ln === 'en' ? s.right : '')} />
						</div>
						<img src={globeIcon} />
					</div>

					<div className={cx(s.searchBar, { [s.active]: searching })}>
						<input type={"text"} value={query} onChange={handleChange} placeholder={"搜索"}/>
						<img src={searchIcon} onClick={toggleSearch} />
					</div>

					<SearchPane query={query} handleChange={handleChange} visible={searching} />

					<nav className={cx({ [s.collapsed]: searching })}>

						<div className={s.collapsedIcon} onClick={toggleSearch}>
							<span />
							<span />
							<span />
						</div>

						{menu}

						{/*<NavLink to={"/research"}>研发&品质</NavLink>*/}
						{/*<NavLink to={"/facilities"}>设备展示</NavLink>*/}
						{/*<NavLink to={"/products"}>产品中心</NavLink>*/}
						{/*<NavLink to={"/resources"}>走进科森</NavLink>*/}
						{/*<NavLink to={"/news"}>新闻中心</NavLink>*/}
						{/*<NavLink to={"/resources"}>伙伴合作</NavLink>*/}
						{/*<NavLink to={"/resources"}>加入科森</NavLink>*/}

					</nav>

				</div>

				{/*<div className={s.dropDownShade} />*/}

			</header>

		</div>
	)
}

const mapState = (state) => {
	return {
		ln: state.session.ln,
		productCategories: state.content.productCategories,
		equipmentCategories: state.content.equipmentCategories
	}
}

export default connect(mapState, {setLanguage})(Header)
