import React from 'react'
import {Link} from "react-router-dom"

import s from './Tile.module.sass'

function EquipmentTile(props) {

	const ln = props.ln

	return (
		<Link to={"/equipment-info/" + props._id} className={s.tile}>
			<div className={s.cover}>
				<img src={props.getImage('thumb')} />
			</div>
			<div className={s.text}>{props.getName(ln)}</div>
		</Link>
	)
}

export default EquipmentTile
