import React, {useState, useEffect} from 'react'
import {useParams, withRouter} from 'react-router-dom'
import {connect} from 'react-redux'

import API from "library/API"
import EquipmentTile from "components/EquipmentTile"
import Equipment from "../library/Equipment"

import s from './Equipment.module.sass'
import bannerSrc from "images/layout/clean-blue-white-factory-floor.jpg"
import Pager from "../components/Pager"

function Page(props) {

	const ln = props.ln
	const slug = useParams().category
	const [category, setCategory] = useState({ name: { en: '', zh: '' }})
	const [equipmentData, setEquipmentData] = useState([])

	useEffect(async () => {
		if (props.equipmentCategories.length === 0) return
		let foundCategory = await props.equipmentCategories.find(obj => obj.slug === slug)
		await setCategory(foundCategory)

		let api = new API()
		let data = await api.getEquipment({ category: foundCategory._id })
		setEquipmentData(data.equipment)

	}, [props.equipmentCategories])



	let equipmentCount = equipmentData.length
	let pageLimit = 6
	let pages = Math.ceil(equipmentCount / pageLimit)
	const [activePage, setActivePage] = useState(1)
	let startItem = (activePage - 1) * pageLimit
	let endItem = startItem + pageLimit

	let equipment = equipmentData.map((item, x) => {
		if (x >= startItem && x < endItem) {
			let eqp = new Equipment(item)
			return <EquipmentTile {...eqp} ln={ln} />
		}
	})

	function handlePagerClick(page) {
		let titleElement = document.querySelector('h1')
		let top = titleElement.offsetTop
		window.scrollTo({ top: top, behavior: 'smooth' })
		setTimeout(() => setActivePage(page), 500)
	}


	return (
		<main>
			<div className={"banner"}>
				<img src={bannerSrc} style={{ opacity: 0.5 }} />
				<div className={"fade"} />
				<h1>{ln === 'zh' ? <>设备</> : <>Equipment</>}</h1>
			</div>

			<section className={"fixed padded"}>

				<h2>{category ? category.name[ln] : ''}</h2>

				<div className={s.tiles}>{equipment}</div>

				<Pager pages={pages} activePage={activePage} handlePagerClick={handlePagerClick} />

			</section>

		</main>
	)

}

const mapState = (state) => {
	return {
		ln: state.session.ln,
		equipmentCategories: state.content.equipmentCategories
	}
}

export default connect(mapState)(withRouter(Page))
