
function Job(props = {}) {

	this._id = props._id
	this.category = props.category
	this.title = {}
	if (props.title) {
		this.title.en = props.title.en
		this.title.zh = props.title.zh
	}
	this.location = {}
	if (props.location) {
		this.location.en = props.location.en
		this.location.zh = props.location.zh
	}
	this.salary = props.salary
	this.qty = props.qty
	this.description = {}
	if (props.description) {
		this.description.en = props.description.en
		this.description.zh = props.description.zh
	}
	this.published = props.published
	this.requirements = {}
	if (props.requirements) {
		this.requirements.en = props.requirements.en
		this.requirements.zh = props.requirements.zh
	}


	this.getName = (ln) => {
		if (!ln) ln = 'zh'
		return this.name[ln]
	}

	this.getLink = () => {
		return '/job/' + this._id
	}

}

export default Job
