import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import Product from "library/Product"

import s from './Tile.module.sass'

function ProductTile(props) {

	const ln = props.ln
	const product = new Product(props)
	// useEffect(() => {
	// 	product = new Product(props)
	// }, [])

	return (
		<Link to={product.getLink()} className={s.tile}>
			<div className={s.cover}>
				<img src={product.getImage('thumb')} />
			</div>
			<div className={s.text}>
				■ {product.getName(ln)}
			</div>
		</Link>
	)
}

const mapState = state => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState)(ProductTile)
