
// Global things

export function setLanguage(ln) {
    return {
        type: 'SET_LANGUAGE',
        ln: ln
    }
}

let stateSchema = {
    ln: 'zh'
}

const SessionReducer = (state = stateSchema, action) => {

    switch(action.type) {

        case 'SET_LANGUAGE':
            return {
                ...state,
                ln: action.ln
            }
        default:
            return {...state}

    }

}

export default SessionReducer
