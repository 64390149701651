import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'

import bannerImg from 'images/layout/female-engineer-quality-controlling-components.jpg'

import API from "../library/API"
import PageContent from "../components/PageContent"

function Page(props) {

	const ln = props.ln
	const [page, setPage] = useState({})

	useEffect(() => {
		let api = new API()
		api.getPages({ slug: 'quality-management' })
			.then(res => {
				let page = res.pages[0]
				if (page) setPage(page)
			})

	}, [])

	return (
		<main>
			<div className={"banner"}>
				<img src={bannerImg} />
				<div className={"fade"} />
				<div className={"topFade"} />
				<h1>{ln === 'zh' ? <>品质管理</> : <>Quality Management</>}</h1>
			</div>

			<section className={"fixed padded"} style={{ textAlign: 'center' }}>

				<section className={"fixed padded"}>
					<PageContent {...page} />
				</section>

			</section>

		</main>
	)

}

const mapState = (state) => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState)(Page)
