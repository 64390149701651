import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'

import bannerSrc from 'images/layout/business-partners-shaking-hands-spiral-staircase.jpg'

import s from './Awards.module.sass'
import AboutLinks from "components/AboutLinks"
import ContactTile from "components/ContactTile"
import API from "../library/API";
import PageContent from "../components/PageContent"

function Page(props) {

	const [page, setPage] = useState({})
	let ln = props.ln

	useEffect(() => {
		let api = new API()
		api.getPages({ slug: 'awards' })
			.then(res => {
				let page = res.pages[0]
				console.log(page)
				if (page) setPage(page)
			})

	}, [])

	return (
		<main>
			<div className={"banner"}>
				<img src={bannerSrc} style={{ opacity: 0.5 }} />
				<div className={"fade"} />
				<h1>{page.title && page.title[ln]}</h1>
			</div>

			<section className={"fixed padded"}>

				<div className={"withSidebar"}>

					<div className={"sidebar"}>
						<AboutLinks />
						<ContactTile />
					</div>

					<div className={"body"}>
						<PageContent {...page} className={s.awards} />
					</div>

				</div>

			</section>

		</main>
	)

}

const mapState = state => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState)(Page)
