import {Link} from 'react-router-dom'
import {useSelector} from "react-redux"

import s from './JobTile.module.sass'

function JobTile(props) {

	const ln = useSelector(state => state.session.ln)

	return (
		<Link className={s.tile} to={"/job/" + props._id}>
			<div>
				{props.title[ln]}
			</div>
			<div>
				{new Date(props.published).toLocaleDateString()}
			</div>
		</Link>
	)

}

export default JobTile
