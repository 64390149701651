import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import Post from "library/Post"

import {getFormattedDate} from "library/Core"

import s from './PostTile.module.sass'

function PostTile(props) {

	const ln = props.ln
	const className = props.mode === 'list' ? s.list : s.tile
	const post = new Post(props)

	return (
		<Link to={"/article/" + post._id} className={className}>
			<div className={s.cover}>
				<img src={post.getCover('thumb')} />
			</div>
			<div className={s.date}>{getFormattedDate(post.published) || 'n/a'}</div>
			<div className={s.title}>{post.getTitle(ln)}</div>
		</Link>
	)
}

const mapState = state => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState)(PostTile)
