import React from "react"
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'

import Links from "library/Links"

import s from './Footer.module.sass'

function Footer(props) {

	const ln = props.ln

	let catLinks = props.productCategories.map(cat => <Link to={"/products/" + cat.slug}>{cat.name[ln]}</Link>)
	let eqpLinks = props.equipmentCategories.map(cat => <Link to={"/equipment/" + cat.slug}>{cat.name[ln]}</Link>)

	let links = Links.map(group => {
		return (
			<div className={s.column}>
				<div className={s.heading}>{group.name[ln]}</div>
				{group.links.map(link => <Link to={link.href}>{link.name[ln]}</Link>)}
				{group.name.en === 'Products' && catLinks}
				{group.name.en === 'Equipment' && eqpLinks}
			</div>
		)
	})

	return (
		<footer className={s.footer}>

			<div className={s.columns}>

				{links}

			</div>


			<div className={s.byline}>
				<span>&copy; {new Date().getFullYear()} Kersentech</span>
				<span><a href={"https://beian.miit.gov.cn/#/Integrated/index"} target={"_blank"}>苏ICP备08120450号-1</a></span>
			</div>

		</footer>
	)
}

const mapState = state => {
	return {
		ln: state.session.ln,
		productCategories: state.content.productCategories,
		equipmentCategories: state.content.equipmentCategories
	}
}

export default connect(mapState)(Footer)
