import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import API from "../library/API";
import bannerSrc from "../images/layout/business-partners-shaking-hands-couch-meeting.jpg"
import AboutLinks from "../components/AboutLinks"
import ContactTile from "../components/ContactTile"
import PageContent from "../components/PageContent"

import s from './Staff.module.sass'
import Pager from "../components/Pager";

function Page(props) {

	const [page, setPage] = useState({})
	const ln = props.ln

	useEffect(() => {
		let api = new API()
		api.getPages({ slug: 'staff' })
			.then(res => {
				let page = res.pages[0]
				console.log(page)
				if (page) {
					setPage(page)
					setTimeout(() => {
						parseBody()
					}, 100)
				}
			})

	}, [])

	const [count, setCount] = useState(0)
	let pageLimit = 6
	let pages = Math.ceil(count / pageLimit)
	const [activePage, setActivePage] = useState(1)

	function parseBody() {
		let figures = document.querySelectorAll('figure')
		setCount(figures.length)
		handleDisplay(1)
	}

	function handlePagerClick(page) {
		let titleElement = document.querySelector('h1')
		let top = titleElement.offsetTop
		window.scrollTo({ top: top, behavior: 'smooth' })
		setTimeout(() => {
			handleDisplay(page)
			setActivePage(page)
		}, 500)
	}

	function handleDisplay(page) {
		let startItem = (page - 1) * pageLimit
		let endItem = startItem + pageLimit
		let figures = document.querySelectorAll('figure')
		let x = 0
		for (let figure of figures) {
			figure.style.display = x >= startItem && x < endItem ? 'block' : 'none'
			x++
		}
	}

	return (
		<main>
			<div className={"banner"}>
				<img src={bannerSrc} style={{ opacity: 0.5 }} />
				<div className={"fade"} />
				<h1>{page.title && page.title[ln]}</h1>
			</div>

			<section className={"fixed padded " + s.wrapper}>

				<div className={"withSidebar"}>

					<div className={"sidebar"}>
						<AboutLinks />
						<ContactTile />
					</div>

					<div className={"body " + s.gallery}>
						<PageContent {...page} />
						<Pager pages={pages} activePage={activePage} handlePagerClick={handlePagerClick} />
					</div>

				</div>

			</section>

		</main>
	)

}

const mapState = (state) => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState)(Page)
