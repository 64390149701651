import React, {useState, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {connect} from 'react-redux'

import Product from "library/Product"

import bannerSrc from 'images/layout/car-during-assembly.jpg'
import API from "../library/API"

import s from './Product.module.sass'

function ProductPage(props) {

	const ln = props.ln
	const params = useParams()
	const [product, setProduct] = useState(new Product())

	useEffect(() => {
		let _id = params._id

		let api = new API()
		api.getProduct(_id)
			.then(res => {
				console.log(res)
				setProduct(new Product(res.product))
			})
	}, [])

	let cat = props.productCategories.find(obj => obj._id === product.category)
	let slug = cat && cat.slug

	return (
		<main style={{ paddingBottom: '100px', background: 'black'}}>

			<div className={"bg2"} />

			<div className={"banner2"}>
				<div className={"banner-image"}>
					<img src={bannerSrc} />
					<div className={"banner-image-fade"} />
				</div>
				<h1>{product.name[ln]}</h1>
			</div>

			<section className={"fixed2"}>

				<Link className={s.back} to={"/products/" + slug}>《 {ln === 'zh' ? '返回' : 'Back'}</Link>

				<div className={s.image}>
					<img src={product.getImage()} />
				</div>

				{
					product.description[ln] &&
					(
						<div className={s.description}>
							{product.description[ln]}
						</div>
					)
				}

			</section>

		</main>
	)

}

const mapState = state => {
	return {
		ln: state.session.ln,
		productCategories: state.content.productCategories
	}
}

export default connect(mapState)(ProductPage)
