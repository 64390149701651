import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'

import bannerImg from 'images/layout/executive-boardroom.jpg'

import s from './Chairman.module.sass'
import API from "../library/API"
import PageContent from "../components/PageContent"

function Page(props) {

	const [page, setPage] = useState({})
	let ln = props.ln

	useEffect(() => {
		let api = new API()
		api.getPages({ slug: 'chairman' })
			.then(res => {
				let page = res.pages[0]
				console.log(page)
				if (page) setPage(page)
			})

	}, [])

	return (
		<main className={s.wrapper} style={{background: '#ccc'}}>

			<div className={"banner2"}>
				<img src={bannerImg} style={{ position: 'fixed', height: '100vh', opacity: 0.3 }}/>
				<div className={"topFade"} />
				<h1>{ln === 'zh' ? <>董事长致辞</> : <>Message from the Chairman</>}</h1>
			</div>

			<section className={"fixed2"}>

				<PageContent {...page} />

			</section>

		</main>
	)

}

const mapState = (state) => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState)(Page)
