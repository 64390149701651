import React from 'react'
import cx from 'classnames'

import s from './Pager.module.sass'

function Pager(props) {

	let buttons = []
	for (let x = 1; x <= props.pages; x++) {
		buttons.push(
			<span className={cx({ [s.active]: x === props.activePage })} onClick={() => props.handlePagerClick(x)}>
				{x}
			</span>
		)
	}

	return (
		<div className={s.pager}>
			{buttons}
		</div>
	)

}

export default Pager
