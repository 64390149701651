import React from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import s from './ContactTile.module.sass'

function ContactTile(props) {

	const ln = props.ln

	return (
		<Link to={"/contact"} className={s.tile}>
			<div className={s.heading}>{ln === 'zh' ? '联系我们' : 'Contact Us'}</div>
			<div>{ln === 'zh' ? '如需帮助直接联系我们' : 'Get in touch with us for any inquiries'}</div>
		</Link>
	)

}

const mapState = state => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState)(ContactTile)
