export function getRefactoredImage(filename, type) {
	if (!type) return filename
	return filename.replace('.', '-' + type + '.')
}

export function getFormattedDate(dt) {
	if (!dt || dt === '') return null
	console.log('passing dt', dt)
	dt = new Date(dt)
	return dt.toLocaleDateString()
	// return dt.getFullYear() + ' ' + dt.getMonth() + ' ' + dt.getDate()
}
