import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'

import Map from "components/Map"
import API from "../library/API"
import PageContent from "../components/PageContent"

import s from './Contact.module.sass'
import bannerSrc from "../images/layout/solar-panels-in-rows-facing-forward.jpg"

function ContactPage(props) {

	const ln = props.ln
	const [page, setPage] = useState({})

	useEffect(() => {
		let api = new API()
		api.getPages({ slug: 'contact' })
			.then(res => {
				let page = res.pages[0]
				if (page) setPage(page)
			})

	}, [])

	return (
		<main>
			<div className={"banner"}>
				<img src={bannerSrc} style={{ opacity: 0.5 }} />
				<div className={"fade"} />
				<h1>{ln === 'en' ? 'Contact Us' : '联系我们'}</h1>
			</div>

			<section className={"fixed padded"}>

				<div className={s.columns}>
					<PageContent {...page} />
				</div>

				{/*<p>*/}
				{/*	总机电话：0512-57478666<br />*/}
				{/*	传真号码：0512-57478678<br />*/}
				{/*	公司地址：中国·江苏 昆山市开发区新星南路155号*/}
				{/*</p>*/}

				{/*<h2>销售部门：</h2>*/}

				{/*<p>*/}
				{/*	<h3>海外业务(3C/医疗/汽车)</h3>*/}
				{/*	马小姐(Yvonne)<br />*/}
				{/*	+1（415）818 6106<br />*/}
				{/*	+（86）13914011109<br />*/}
				{/*	Yvonne.Ma@kersentech.com*/}
				{/*</p>*/}

				{/*<p>*/}
				{/*	<h3>台湾/日本(3C/医疗/汽车)</h3>*/}
				{/*	程先生(Fonda)<br />*/}
				{/*	13912655228<br />*/}
				{/*	Fonda.Cheng@kersentech.com*/}
				{/*</p>*/}

				{/*<p>*/}
				{/*	<h3>国内业务 汽车</h3>*/}
				{/*	曹小姐(Helen)<br />*/}
				{/*	17751228739<br />*/}
				{/*	Helen.Cao@kersentech.com*/}
				{/*</p>*/}

				{/*<p>*/}
				{/*	<h3>医疗</h3>*/}
				{/*	姜先生(Frank)<br/>*/}
				{/*	13918189950<br/>*/}
				{/*	Frank.Jiang@kersentech.com*/}
				{/*</p>*/}

				{/*<p>*/}
				{/*	<h3>3C</h3>*/}
				{/*	文先生(Owen)<br/>*/}
				{/*	13776363081<br/>*/}
				{/*	Owen.Wen@kersentech.com*/}
				{/*</p>*/}

				{/*<h2>采购部门：</h2>*/}

				{/*<p>*/}
				{/*	陈先生(Miller)<br/>*/}
				{/*	0512-36866129<br/>*/}
				{/*	Miller.Chen@kersentech.com*/}
				{/*</p>*/}

				{/*<h2>招聘部门：</h2>*/}

				{/*<p>*/}
				{/*	罗小姐(Sara)<br/>*/}
				{/*	0512-36860851<br/>*/}
				{/*	Sara.Luo@kersentech.com*/}
				{/*</p>*/}

				<br/>
				<br/>

				<Map />

			</section>

		</main>
	)

}

const mapState = state => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState)(ContactPage)
