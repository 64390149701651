
// Global things

export function setProductCategories(categories) {
    for (let cat of categories) {
        cat.slug = cat.name.en.toLowerCase().replace(/ /g, '-')
    }
    console.log('modified product categories', categories)
    return {
        type: 'SET_PRODUCT_CATEGORIES',
        categories: categories
    }
}

export function setEquipmentCategories(categories) {
    for (let cat of categories) {
        cat.slug = cat.name.en.toLowerCase().replace(/ /g, '-')
    }
    console.log('modified equipment categories', categories)
    return {
        type: 'SET_EQUIPMENT_CATEGORIES',
        categories: categories
    }
}

let stateSchema = {
    productCategories: [],
    equipmentCategories: [],
    jobCategories: []
}

const ContentReducer = (state = stateSchema, action) => {

    switch(action.type) {

        case 'SET_PRODUCT_CATEGORIES':
            return {
                ...state,
                productCategories: action.categories
            }
        case 'SET_EQUIPMENT_CATEGORIES':
            return {
                ...state,
                equipmentCategories: action.categories
            }
        case 'SET_JOB_CATEGORIES':
            return {
                ...state,
                jobCategories: action.categories
            }
        default:
            return {...state}

    }

}

export default ContentReducer
