import React from 'react'
import {NavLink} from "react-router-dom"
import {connect} from 'react-redux'

import Links from 'library/Links.js'

import s from './AboutLinks.module.sass'

let aboutLinks = Links[0].links.filter(obj => obj.href !== '/')

function AboutLinks(props) {

	let ln = props.ln
	console.log(aboutLinks)

	let links = aboutLinks.map(link => {
		return <NavLink to={link.href} className={s.link}>{link.name[ln]}</NavLink>
	})

	return (
		<div className={s.links}>
			{links}
		</div>
	)

}

const mapState = state => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState)(AboutLinks)
