import React, {useEffect} from "react"
import {Route, Switch, useLocation} from "react-router-dom"


import HomePage from "./pages/Home"
import CompanyPage from "./pages/Company"
import ChairmanPage from "./pages/Chairman"
import AwardsPage from "./pages/Awards"
import CulturePage from "./pages/Culture"
import ResponsibilityPage from "./pages/Responsibility"
import ProductsPage from "./pages/Products"
import ResearchPage from "./pages/Research"
import QualityPage from "./pages/Quality"
import EquipmentPage from "./pages/Equipment"
import NewsPage from "./pages/News"
import ArticlePage from "./pages/Article"
import PartnersPage from "./pages/Partners"
import HumanResourcesPage from "./pages/HR"
import StaffPage from "./pages/Staff"

import RecruitingPage from "./pages/Recruiting"
import JobPage from "./pages/Job"

import NotFoundPage from "./pages/NotFound"
import ProductPage from "./pages/Product"
import ContactPage from "./pages/Contact"
import SupplyChainPage from "./pages/SupplyChain"
import EquipmentInfo from "./pages/EquipmentInfo";

function Pages() {

	const location = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [location.pathname])

	return (
		<Switch location={location} key={location.pathname}>


			<Route exact path={"/"}>
				<HomePage />
			</Route>


			<Route exact path={"/introduction"}>
				<CompanyPage />
			</Route>

			<Route exact path={"/chairman"}>
				<ChairmanPage />
			</Route>

			<Route exact path={"/awards"}>
				<AwardsPage />
			</Route>

			<Route exact path={"/culture"}>
				<CulturePage />
			</Route>

			<Route exact path={"/responsibility"}>
				<ResponsibilityPage />
			</Route>


			<Route exact path={"/products/:category"}>
				<ProductsPage />
			</Route>


			<Route exact path={"/product/:_id"}>
				<ProductPage />
			</Route>


			<Route exact path={"/research-and-development"}>
				<ResearchPage />
			</Route>

			<Route exact path={"/quality-management"}>
				<QualityPage />
			</Route>


			<Route path={"/equipment/:category"}>
				<EquipmentPage />
			</Route>

			<Route path={"/equipment-info/:_id"}>
				<EquipmentInfo />
			</Route>


			<Route exact path={"/news/:type"}>
				<NewsPage />
			</Route>

			{/*<Route exact path={"/news/industry"}>*/}
			{/*	<IndustryNewsPage />*/}
			{/*</Route>*/}

			<Route exact path={"/article/:_id"}>
				<ArticlePage />
			</Route>


			<Route exact path={"/partners"}>
				<PartnersPage />
			</Route>

			<Route exact path={"/supply-chain"}>
				<SupplyChainPage />
			</Route>

			<Route exact path={"/hr"}>
				<HumanResourcesPage />
			</Route>

			<Route exact path={"/staff"}>
				<StaffPage />
			</Route>



			<Route exact path={"/recruiting"}>
				<RecruitingPage />
			</Route>

			<Route exact path={"/job/:_id"}>
				<JobPage />
			</Route>


			<Route exact path={"/contact"}>
				<ContactPage />
			</Route>


			<Route path={"/"}>
				<NotFoundPage />
			</Route>


		</Switch>
	)

}

export default Pages
