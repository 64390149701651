import React, {useEffect, useState, useRef} from 'react'
import {connect, useSelector, useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import cx from 'classnames'

import API from "../library/API"
import Job from "../library/Job"
import {getFormattedDate} from "../library/Core"

import s from './Job.module.sass'
import bannerSrc from "../images/layout/pexels-pixabay-257727.jpg"


function JobPage() {

	const ln = useSelector(state => state.session.ln)
	const dispatch = useDispatch()
	const jobCategories = useSelector(state => state.content.jobCategories)
	const [job, setJob] = useState(new Job())
	const [fields, setFields] = useState({})
	const [applied, setApplied] = useState(false)
	const [msg, setMsg] = useState({ text: '.', type: null })
	const params = useParams()
	const _id = params._id
	const ref = useRef()

	useEffect(() => {
		let api = new API()
		api.getJob(_id)
			.then(res => {
				console.log(res)
				setJob(new Job(res.job))
				setFields({ ...fields, job: res.job._id })
			})

		if (!jobCategories?.length) {
			console.log('should be getting cat')
			api.getJobCategories()
				.then(res => {
					console.log('job cats', res.categories)
					dispatch({ type: 'SET_JOB_CATEGORIES', categories: res.categories })
				})
		}

	}, [])

	useEffect(() => {
		console.log('job', jobCategories)

	}, [])

	function handleChange(e) {
		setFields({
			...fields,
			[e.target.name]: e.target.value
		})
	}

	function handleFile(e) {
		setFields({ ...fields, cv: e.target.files })
	}

	function handleSubmit(e) {
		e.preventDefault()
		// console.log(fields)

		// Validate fields first
		if (!fields.name) {
			console.log('should be invalid')
			setMsg({ text: ln === 'zh' ? '请输入您的名字' : 'Please enter your name', type: 'error' })
			scrollUp()
			return
		}
		if (!fields.cv) {
			setMsg({ text: ln === 'zh' ? '请上传您的简历' : 'Please upload your resume', type: 'error' })
			scrollUp()
			return
		}

		let api = new API()
		api.submitApplication(fields)
			.then(res => {
				// console.log(res)
				if (res) {
					setApplied(true)
					setMsg({ text: ln === 'zh' ? '申请成功了' : 'Application Submitted', type: 'standard' })
					scrollUp()
				}
			})


	}

	function scrollUp() {
		let offset = ref.current.offsetTop - 100
		window.scrollTo({ top: offset, behavior: 'smooth' })
	}

	return (
		<main>
			<div className={"banner"}>
				<img src={bannerSrc} style={{ opacity: 0.8 }} />
				<div className={"fade"} />
				<h1>{job.title && job.title[ln]}</h1>
			</div>

			<section className={"fixed padded"}>
				<div className={s.details}>
					<div className={s.item}>
						{job.location[ln]}
						<label>{ln === 'zh' ? '工作地点' : 'Location'}</label>
					</div>
					<div className={s.item}>
						{job.salary || (ln === 'zh' ? '面议' : 'Negotiable')}
						<label>{ln === 'zh' ? '薪资' : 'Salary'}</label>
					</div>
					<div className={s.item}>
						{jobCategories.find(obj => obj._id === job.category)?.name[ln] || 'n/a'}
						<label>{ln === 'zh' ? '职位类别' : 'Category'}</label>
					</div>
					<div className={s.item}>
						{job.qty || (ln === 'zh' ? '若干' : 'Several')}
						<label>{ln === 'zh' ? '招聘人数' : 'Openings'}</label>
					</div>
					<div className={s.item}>
						{getFormattedDate(job.published)}
						<label>{ln === 'zh' ? '发布时间' : 'Published'}</label>
					</div>
				</div>

				<div className={s.tag}>{ln === 'zh' ? '职位描述' : 'Job Description'}</div>

				<div dangerouslySetInnerHTML={{ __html: job.description && job.description[ln] }} />

				<div className={s.tag}>{ln === 'zh' ? '任职要求' : 'Requirements'}</div>

				<div dangerouslySetInnerHTML={{ __html: job.requirements && job.requirements[ln] }} />

				<div className={cx(s.message, { [s.success]: msg.type === 'standard', [s.error]: msg.type === 'error' } )} ref={ref}>
					{msg.text}
				</div>

				<form onSubmit={handleSubmit} className={cx(s.application, { [s.inactive]: applied } )}>
					<h2>{ln === 'en' ? 'Application' : '就业申请'}</h2>

					<label>{ln === 'en' ? 'Name' : '名字'}</label>
					<input type={"text"} name={"name"} value={fields.name} disabled={applied} onChange={handleChange} />

					<label>{ln === 'en' ? 'Cover Letter (optional)' : '求职信（可选）'}</label>
					<textarea name={"cover"} value={fields.cover} disabled={applied} onChange={handleChange} />

					<input type={"file"} name={"cv"} disabled={applied} onChange={handleFile} accept={".pdf, .doc, .docx, .txt"}/>
					<legend>有效格式：.pdf, .doc / .docx, .txt</legend>

					<input type={"submit"} disabled={applied} value={ln === 'en' ? 'Apply' : "提交"} />
				</form>

			</section>

		</main>
	)


}

export default JobPage
