import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'

import bannerImg from 'images/layout/engineers-using-a-computer.jpg'

import s from './Research.module.sass'
import API from "../library/API"
import PageContent from "../components/PageContent"

function Page(props) {

	const ln = props.ln
	const [page, setPage] = useState({})

	useEffect(() => {
		let api = new API()
		api.getPages({ slug: 'research-and-development' })
			.then(res => {
				let page = res.pages[0]
				if (page) setPage(page)

				setTimeout(() => {
					removeInlineStyles()
				}, 500)
			})

	}, [])

	function removeInlineStyles() {
		let figures = document.querySelectorAll('figure')
		for (let figure of figures) {
			console.log(figure)
			figure.style = ''
		}
	}

	return (
		<main>
			<div className={"banner"}>
				<img src={bannerImg} />
				<h1>{ln === 'zh' ? <>研发创新</> : <>Research & Development</>}</h1>
			</div>

			<section className={"fixed padded " + s.wrapper}>
				<PageContent {...page} />
			</section>

		</main>
	)

}

const mapState = (state) => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState)(Page)
