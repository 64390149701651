import React from 'react'
import cx from 'classnames'

import s from './NewsOptions.module.sass'

function NewsOptions(props) {

	return (
		<div className={s.options}>
			<div className={cx(s.tiles, { [s.active]: props.mode === 'tile' })}
				onClick={() => props.setMode('tile')}>
				<span />
				<span />
				<span />
				<span />
			</div>
			<div className={cx(s.list, { [s.active]: props.mode === 'list' })}
				onClick = {() => props.setMode('list')}>
				<span />
				<span />
				<span />
			</div>
		</div>
	)

}

export default NewsOptions
