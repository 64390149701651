import React, {useState, useEffect} from 'react'
import {BrowserRouter, Switch, Route} from "react-router-dom"
import {connect} from 'react-redux'

import {setProductCategories, setEquipmentCategories} from "./reducers/Content"

import Header from "./includes/Header"
import Footer from "./includes/Footer"
import Pages from "./Pages"

import API from "./library/API"

function App(props) {

    const [connected, setConnected] = useState(false)

    useEffect(() => {
        // Get categories and set globally
        let api = new API()
        api.getProductCategories()
            .then(res => {
                props.setProductCategories(res.categories)
                setConnected(true)
            })

        api.getEquipmentCategories()
            .then(res => {
                props.setEquipmentCategories(res.categories)
            })

    }, [])

    return (
        <div className={props.ln}>
            <BrowserRouter>

                {/*{connected || <div style={{ position: 'fixed', zIndex: 10000, height: '100vh', width: '100vw', background: '#fcfcfc' }} />}*/}

                <Header />

                <Pages />

                <Footer />

            </BrowserRouter>
        </div>
    )
}

const mapState = state => {
    return {
        ln: state.session.ln
    }
}

export default connect(mapState, {setProductCategories, setEquipmentCategories})(App)
