import React, {useState, useEffect} from 'react'
import {useParams} from "react-router-dom"
import {connect} from 'react-redux'
import API from "../library/API"
import ProductTile from "../components/ProductTile"
import Pager from "../components/Pager"

import bannerSrc from 'images/layout/car-during-assembly.jpg'

import s from './Products.module.sass'

function ProductsPage(props) {

	let ln = props.ln
	const params = useParams()
	const [productData, setProductData] = useState([])
	const [category, setCategory] = useState({ name: { en: '', zh: '' }})
	let slug = params.category

	useEffect(async () => {
		if (props.productCategories.length === 0) return
		// console.log('slug to get', category)
		// console.log(props.productCategories)
		let foundCategory = await props.productCategories.find(obj => obj.slug === slug)
		// console.log('found', foundCategory)
		await setCategory(foundCategory)

		let api = new API()
		let data = await api.getProducts({ category: foundCategory._id })
		setProductData(data.products)

	}, [props.productCategories])


	let productCount = productData.length
	let pageLimit = 6
	let pages = Math.ceil(productCount / pageLimit)
	const [activePage, setActivePage] = useState(1)
	let startItem = (activePage - 1) * pageLimit
	let endItem = startItem + pageLimit

	let products = productData.map((product, x) => {
		if (x >= startItem && x < endItem) return <ProductTile {...product} />
	})

	function handlePagerClick(page) {
		let titleElement = document.querySelector('h1')
		let top = titleElement.offsetTop
		window.scrollTo({ top: top, behavior: 'smooth' })
		setTimeout(() => setActivePage(page), 500)
	}

	return (
		<main style={{background: 'black', paddingBottom: '50px' }}>

			<div className={"bg2"} />

			<div className={"banner2"}>
				<div className={"banner-image"}>
					<img src={bannerSrc} />
					<div className={"banner-image-fade"} />
				</div>
				<h1>{ln === 'zh' ? <>产品中心</> : <>Products</>}</h1>
			</div>

			<section className={"fixed2"}>

				<h2>{category ? category.name[ln] : ''}</h2>

				<div className={s.products}>
					{products}
				</div>

				<Pager pages={pages} activePage={activePage} handlePagerClick={handlePagerClick} />

			</section>

		</main>
	)

}

const mapState = state => {
	return {
		ln: state.session.ln,
		productCategories: state.content.productCategories
	}
}

export default connect(mapState)(ProductsPage)
