import React, {useState, useEffect} from "react"
import {connect} from 'react-redux'
import {Link} from "react-router-dom"

import {getFormattedDate} from "../library/Core"
import API from "../library/API"
import Post from "../library/Post"

import s from './NewsFeed.module.sass'

function NewsFeed(props) {

	const ln = props.ln
	const [articleData, setArticleData] = useState([])

	useEffect(() => {
		console.log('language has changed')
		let api = new API()
		api.getPosts({ ln: ln })
			.then(res => {
				console.log(res)
				let firstThree = res.posts.slice(0, 3)
				setArticleData(firstThree)
			})
	}, [props.ln])

	let articles = articleData.map(article => {
		let post = new Post(article)
		return (
			<div className={s.article + ' ' + s.enter}>

				<div className={s.cover}>
					<img src={post.getCover('thumb')} />
				</div>

				<div className={s.published}>{getFormattedDate(post.published)}</div>

				<div className={s.tab}>
					{article.title[ln]}
				</div>

				<Link to={"/article/" + post._id} className={s.link}>
					{ln === 'zh' ? <>查看文章</> : <>View Article</>}
				</Link>

			</div>
		)
	})

	return (
		<section className={s.news}>

			{/*<h2>新闻中心</h2>*/}

			<div className={s.articles}>
				{articles}
			</div>

		</section>
	)

}

const mapState = state => {
	return {
		ln: state.session.ln
	}
}

export default connect(mapState)(NewsFeed)
