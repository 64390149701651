import React, {useEffect} from 'react'

function Map() {

	useEffect(() => {
		// GL版命名空间为BMapGL
		// 按住鼠标右键，修改倾斜角和角度

		try {
			let point = new window.BMapGL.Point(121.089116, 31.350927)

			let map = new window.BMapGL.Map("allmap")   // 创建Map实例
			map.centerAndZoom(point, 19);  // 初始化地图,设置中心点坐标和地图级别
			map.enableScrollWheelZoom(true)     //开启鼠标滚轮缩放
			map.setHeading(64.5)
			map.setTilt(0)

			let marker = new window.BMapGL.Marker(point)        // 创建标注
			map.addOverlay(marker)
			// const marker = new window.BMap.Marker(new window.BMapGL.Point(121.08257945489366, 31.347145940208673))
			// map.addOverlay(marker)
		} catch (e) {
			console.log(e)
		}
	}, [])

	return (
		<div>
			{/*<script type="text/javascript" src="//api.map.baidu.com/api?type=webgl&v=1.0&ak=u8sEEGk3G1sr4c99mu0L8Qh24USRMOsZ"></script>*/}
			<div id={"allmap"}  style={{ width: '100%', height: '500px' }} />

		</div>
	)

}

export default Map
